// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-en-ilotalo-tsx": () => import("./../../../src/pages/en/ilotalo.tsx" /* webpackChunkName: "component---src-pages-en-ilotalo-tsx" */),
  "component---src-pages-en-index-tsx": () => import("./../../../src/pages/en/index.tsx" /* webpackChunkName: "component---src-pages-en-index-tsx" */),
  "component---src-pages-ilotalo-tsx": () => import("./../../../src/pages/ilotalo.tsx" /* webpackChunkName: "component---src-pages-ilotalo-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-templates-board-template-en-tsx": () => import("./../../../src/templates/BoardTemplateEn.tsx" /* webpackChunkName: "component---src-templates-board-template-en-tsx" */),
  "component---src-templates-board-template-fi-tsx": () => import("./../../../src/templates/BoardTemplateFi.tsx" /* webpackChunkName: "component---src-templates-board-template-fi-tsx" */),
  "component---src-templates-contact-page-template-tsx": () => import("./../../../src/templates/ContactPageTemplate.tsx" /* webpackChunkName: "component---src-templates-contact-page-template-tsx" */),
  "component---src-templates-events-page-template-tsx": () => import("./../../../src/templates/EventsPageTemplate.tsx" /* webpackChunkName: "component---src-templates-events-page-template-tsx" */),
  "component---src-templates-page-template-tsx": () => import("./../../../src/templates/PageTemplate.tsx" /* webpackChunkName: "component---src-templates-page-template-tsx" */)
}

